@import 'src/variables';

.CoinSelector {
    background: #f0f0f0;
    color: #000;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .l-col {
        position: relative;

        &::after {
            background-color: #e3e3e3;
            position: absolute;
            right: 40px;
            top: 0;
            bottom: 0;
            content: '';
            width: 2px;

            @media screen and (min-width: $media375) {
                display: none;
            }

            @media screen and (min-width: $media1024) {
                display: block;
            }
        }
    }

    @media screen and (min-width: $media375) {
        width: 200px;
        height: 55px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: $media1024) {
        width: 116px;
        height: 34px;
        margin-bottom: 0;
    }

    @media screen and (min-width: $media1440) {
        width: 163px;
        height: 42px;
    }

    @media screen and (min-width: $media1920) {
        width: 192px;
        height: 49px;
    }

    img {
        @media screen and (min-width: $media375) {
            width: 23px;
            height: 23px;
            margin-right: 8px;
        }

        @media screen and (min-width: $media1024) {
            width: 23px;
            height: 23px;
            margin-right: 8px;
        }

        @media screen and (min-width: $media1440) {
            width: 21px;
            height: 21px;
            margin-right: 8px;
        }

        @media screen and (min-width: $media1920) {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }

    .coinName {
        font-family: 'Space Grotesk';

        @media screen and (min-width: $media375) {
            font-size: 14px;
            line-height: 16px;
            margin-right: 5px;
        }

        @media screen and (min-width: $media1024) {
            font-size: 11px;
            line-height: 15px;
            margin-right: 5px;
        }

        @media screen and (min-width: $media1440) {
            font-size: 13px;
            line-height: 17px;
            // width: 80px;
        }

        @media screen and (min-width: $media1920) {
            font-size: 14px;
            line-height: 18px;
            // width: 80px;
        }
    }

    .Coin-Selector__Items {
        border-radius: $border-radius;
        position: absolute;
        left: 0px;
        right: 0px;
        background: #f0f0f0;
        top: 55px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 15px 15px 15px 15px;
        z-index: 2;

        @media screen and (min-width: $media375) {
            left: 50%;
            transform: translateX(-50%);
        }

        @media screen and (min-width: $media1440) {
            left: 0;
            transform: none;
        }

        .coin-item {
            display: flex;
            cursor: pointer;
            border-radius: 9px;
            // min-width: 86px;
            height: 33px;
            align-items: center;
            padding: 0 10px;

            @media screen and (min-width: $media1024) {
                width: 90px;
            }

            @media screen and (min-width: $media1440) {
                width: auto;
            }

            &:hover,
            &.selected {
                background-color: #d4d4d4;
            }
        }

        &.big {
            border: 1px solid #e6e6e6;

            @media screen and (min-width: $media375) {
                width: 355px;
                height: auto;
                padding: 37px 40px 40px 40px;
            }

            @media screen and (min-width: $media1024) {
                width: 426px;
                height: 152px;
                padding: 20px 15px;
            }

            @media screen and (min-width: $media1440) {
                width: 426px;
                height: 152px;
                padding: 22px 25px 25px 25px;
            }

            .title {
                font-size: 13px;
                font-weight: 500;
                line-height: 17px;
            }
        }
    }
}

body.dark {
    .Coin-Selector {
        &__Items {
            background-color: #282828 !important;

            .coin-item {
                &:hover,
                &.selected {
                    background-color: #474747 !important;
                }
            }

            .l-col {
                &::after {
                    background-color: #353535;
                }
            }
        }
    }
}
