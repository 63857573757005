@import 'src/variables';

.FastDepositForm {
    background: linear-gradient(81.61deg, #f7f7f7 6.26%, #f5f5f5 93.21%);
    border-radius: $border-radius;
    position: relative;

    @media screen and (min-width: $media375) {
        // margin: 50px 0 20px 0;
        height: 565px;
        // width: 100%;
        padding-top: 70px;
        margin-top: 50px;

        &.mode-UZD,
        &.mode-ZETH {
            padding-top: 110px;
        }
    }

    @media screen and (min-width: $media1024) {
        padding-top: 0;
        padding: 16px;
        height: 222px;
        margin-top: 0;

        &.mode-UZD,
        &.mode-ZETH {
            padding-top: 20px;
        }
    }

    @media screen and (min-width: $media1440) {
        padding: 24px;
        height: 220px;
    }

    @media screen and (min-width: $media1920) {
        padding: 28px;
        height: 262px;
    }

    .zunClaimInput {
        min-width: 170px;
        // height: 40px;
        font-size: 13px;
        justify-content: flex-start;
        padding: 15px;

        .balance {
            font-size: 16px;
            color: $primary-color;
        }
    }

    .checkboxes {
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: $media375) {
            width: 270px;
        }

        @media screen and (min-width: $media1024) {
            width: auto;
        }
    }

    .ActionSelector {
        margin-bottom: 0;
    }

    .disabled-deposit {
        pointer-events: all;
        opacity: 0.5;
    }

    &__Title {
        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1024) {
            display: block;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
        }

        @media screen and (min-width: $media1440) {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
        }

        svg {
            @media screen and (min-width: $media1024) {
                margin-right: 10px;
                width: 23px;
                height: 23px;
            }

            @media screen and (min-width: $media1440) {
                margin-right: 10px;
                width: 37px;
                height: 37px;
            }
        }
    }

    &__Description {
        font-size: 10px;
        line-height: 13.6px;
        text-decoration: none;
        color: #000;

        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1024) {
            display: block;
        }

        @media screen and (min-width: $media1440) {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
        }

        @media screen and (min-width: $media1920) {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .letter {
            fill: #000;
        }
    }

    .Input {
        @media screen and (min-width: $media1440) {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }

    &__Slogan {
        @media screen and (min-width: $media375) {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #404040;
            margin-left: 17px;
        }

        @media screen and (min-width: $media1024) {
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #404040;
            margin-left: 17px;
        }

        @media screen and (min-width: $media1440) {
            font-size: 14px;
            line-height: 19px;
            margin-left: 24px;
        }
    }

    &__MobileToggle {
        @media screen and (min-width: $media375) {
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            width: 300px;
            transform: translate(-50%, -40px);
            z-index: 2;
            text-align: center;
            align-items: center;
        }

        @media screen and (min-width: $media1024) {
            display: none;
        }

        &__Title {
            margin-left: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            margin-top: 10px;
        }

        &__Icon {
            padding: 20px;
            background: linear-gradient(225.25deg, #f7f7f7 16.17%, #f6f6f6 85.98%);
            border-radius: 100%;
            width: 80px;
            height: auto;
        }

        &__ToggleIcon {
            width: 18px;
            height: 6px;
            margin-left: auto;
            margin-right: 33px;
        }
    }

    &__Actions {
        @media screen and (min-width: $media375) {
            margin-bottom: 26px;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            flex-direction: column;
        }

        @media screen and (min-width: $media1024) {
            flex-direction: row;
        }

        @media screen and (min-width: $media1440) {
            margin-bottom: 25px;
            flex-direction: row;
            gap: 0;
            align-items: flex-start;
        }

        @media screen and (min-width: $media1920) {
            margin-bottom: 25px;
        }
    }

    .DirectAction {
        @media screen and (min-width: $media1024) {
            // margin-left: 22px;
        }

        input[type='checkbox'],
        span,
        svg {
            @media screen and (min-width: $media1440) {
                margin-top: 0;
            }
        }

        svg {
            @media screen and (min-width: $media375) {
                margin-top: -3px;
                width: 15px;
                height: 15px;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;

        @media screen and (min-width: $media375) {
            flex-direction: column;
            padding: 10px;
            align-items: center;
            gap: 10px;
        }

        @media screen and (min-width: $media1024) {
            gap: 0;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            padding: 0;
        }
    }
}
