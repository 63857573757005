@import 'src/variables';

.ApyChart {
    @media (min-width: $media375) {
    }

    @media (min-width: $media1024) {
        // height: 130px;
    }

    @media (min-width: $media1440) {
        // height: 150px;
    }

    @media (min-width: $media1920) {
        height: 180px;
    }

    &__Header {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        @media (min-width: $media375) {
            flex-wrap: wrap;
        }

        @media (min-width: $media1024) {
            flex-wrap: nowrap;
        }

        @media (min-width: $media1440) {
            margin: 15px 0 0 0;
        }

        @media (min-width: $media1920) {
        }

        .ApyChart__Title {
            color: #5b5959;

            @media screen and (min-width: $media375) {
                font-size: 12px;
                line-height: 16px;
            }

            @media screen and (min-width: $media1024) {
                font-size: 11px;
                line-height: 15px;
                width: auto;
                margin-bottom: 0;
            }

            @media screen and (min-width: $media1440) {
                font-family: 'Space Grotesk';
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
            }

            @media screen and (min-width: $media1920) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .ApyChart__Selector {
            display: flex;
            flex-direction: row;

            @media screen and (min-width: $media375) {
                grid-gap: 10px;
                gap: 10px;
                margin-top: 20px;
            }

            @media screen and (min-width: $media1024) {
                grid-gap: 10px;
                gap: 10px;
                margin-top: 0;
            }

            @media screen and (min-width: $media1440) {
                grid-gap: 30px;
                gap: 30px;
            }

            @media screen and (min-width: $media1920) {
                grid-gap: 45px;
                margin-top: 20px;
                gap: 45px;
            }

            span {
                cursor: pointer;
                display: block;
                text-align: center;
                color: #636363;

                @media screen and (min-width: $media375) {
                    font-size: 14px;
                    line-height: 14px;
                }

                @media screen and (min-width: $media1024) {
                    font-size: 9px;
                    line-height: 12px;
                }

                @media screen and (min-width: $media1440) {
                    font-size: 10px;
                    line-height: 14px;
                }

                @media screen and (min-width: $media1920) {
                    font-size: 12px;
                    line-height: 15px;
                }

                &.active {
                    color: $primary-color;
                }
            }
        }
    }

    canvas {
        @media screen and (min-width: $media375) {
            margin-top: 50px;
            max-height: 150px !important;
        }

        @media screen and (min-width: $media1024) {
            margin-top: 0;
            max-height: 120px !important;
        }

        @media screen and (min-width: $media1440) {
            margin-top: 0;
            max-height: 150px !important;
        }

        @media screen and (min-width: $media1920) {
            margin-top: 15px;
            max-height: 180px !important;
        }
    }

    .legend-container {
        list-style: none;
        display: flex;
        gap: 15px;
        padding-left: 0;

        @media screen and (min-width: $media375) {
            margin: 10px 0 0 0;
            transform: translateY(0);
            justify-content: center;
            width: 100%;
        }

        @media screen and (min-width: $media1024) {
            width: 60%;
        }

        @media screen and (min-width: $media1440) {
            margin: 5px 0 0 0;
            transform: translateY(-100%);
            justify-content: flex-start;
        }

        li {
            label {
                font-size: 10px;
                line-height: 10px;
                display: flex;
                align-items: center;
                gap: 5px;

                input[type="checkbox"] {
                    width: 16px;
                    height: 16px;
                }

                .icon {
                    width: 7px;
                    height: 7px;
                    border-radius: 3px;
                }
            }
        }
    }
}
